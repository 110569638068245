<template>
  <div class="bg-white shadow-md rounded-xl py-14">
    <div class="max-w-4xl mx-auto">
      <form @submit.prevent="onSubmit()" @keydown="form.onKeydown($event)">
        <div class="mb-6">
          <label class="text-sm font-medium text-gray-900 block mb-2"
            >Current Password</label
          >
          <input
            type="text"
            v-model="form.old_password"
            class="
              bg-gray-50
              border border-gray-300
              text-gray-900
              sm:text-sm
              rounded-lg
              focus:ring-blue-500 focus:border-blue-500
              block
              w-full
              p-2.5
            "
            placeholder="Current password"
            required
          />
        </div>

        <div class="mb-6">
          <label class="text-sm font-medium text-gray-900 block mb-2"
            >New Password</label
          >
          <input
            type="text"
            v-model="form.new_password"
            class="
              bg-gray-50
              border border-gray-300
              text-gray-900
              sm:text-sm
              rounded-lg
              focus:ring-blue-500 focus:border-blue-500
              block
              w-full
              p-2.5
            "
            placeholder="New password"
            required
          />
        </div>

        <div class="mb-6">
          <label class="text-sm font-medium text-gray-900 block mb-2"
            >Confirm Password</label
          >
          <input
            type="text"
            v-model="form.confirm_password"
            class="
              bg-gray-50
              border border-gray-300
              text-gray-900
              sm:text-sm
              rounded-lg
              focus:ring-blue-500 focus:border-blue-500
              block
              w-full
              p-2.5
            "
            placeholder="Confirm password"
            required
          />
        </div>

        <div class="flex justify-end">
          <div class="">
            <button
              @click="$router.push('/settings')"
              class="
                bg-red-500
                text-white
                py-2
                px-6
                rounded-xl
                hover:bg-red-600
                ml-3
              "
            >
              Cancel
            </button>
            <button
              :disabled="form.busy"
              type="submit"
              class="
                bg-green-500
                text-white
                py-2
                px-6
                rounded-xl
                hover:bg-green-600
                ml-3
              "
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import Form from "vform";
// import BranchSales from "../../components/Charts/BranchSales";
import Swal from "sweetalert2";

export default {
  // middleware: "auth",

  // layout: "settings",

  data: () => ({
    roles: null,
    form: new Form({
      old_password: "",
      new_password: "",
      confirm_password: "",
    }),
  }),

  created() {},

  methods: {
    async onSubmit() {
      // Submit the form
      this.isLoading = true;

      try {
        console.log("this.for");

        const data = await this.form.patch("agent/change_password");

        this.isLoading = false;
        console.log("data", data);

        Swal.fire({
          icon: "success",
          title: "Password Updated!",
          text: "Please login using your new password",
        });

        await this.$store.dispatch("auth/logout");

        // Redirect to login.
        this.$router.push({ name: "login" });
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },
  },
};
</script>
